import React, {useState,useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';

import guest from '../../styles/GuestForm/guest.scss';
//Fuction component imports




export default function GuestForm()  {
    //date and time variables 
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    //Set state
    const [uuid, setUuid] = useState(null);
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const [checkInTime, setCheckInTime]= useState(null);
    const [checkOutTime, setCheckOutTime] = useState(null);
    //action varibale delcalration
    const action = isCheckedIn ? 'CheckOut' : 'CheckIn';  

    //Set state for form data
    const [formData, setFormData] = useState({
        ID:'',
        FN:'',
        LN:'',
        company:'',
        date: currentDate,
        CheckInTime: action === 'CheckIn' ? currentTime :  checkInTime,
        CheckOutTime: action === 'CheckOut' ? currentTime : checkOutTime   
    })

    //UUID generation function
    const generateUUID = ( ) => {
        const newUuid = uuidv4();
        setUuid(newUuid);
    };

    useEffect(() => {
        const chkTime= new Date().toLocaleTimeString();
        setCheckInTime(chkTime);
    }, [])
    console.log(checkInTime)

    const setChkInOut = async () => {

        try {
            const currentTime = new Date().toLocaleTimeString();
    
            const action = isCheckedIn ? 'CheckOut' : 'CheckIn'; // Determine the action
            
            if (action === 'CheckIn'){
                generateUUID(); // Perviously generateUUID()
               
            }
            
            const data = {
                ...formData,
                ID:uuid,
                date: currentDate,
                CheckInTime: action === 'CheckIn' ? currentTime :  checkInTime,
                CheckOutTime: action === 'CheckOut' ? currentTime : checkOutTime  
            }
            // Post data to the server
            if(action ==='CheckOut'){
                await postData(data);
                console.log(data);
            }
    
            // Update local state
            if (isCheckedIn) {
                setCheckOutTime(currentTime);
            }
            setIsCheckedIn(!isCheckedIn);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    //Handle change in form data.
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

    const postData = async (data) => {
        try {
            const url = 'http://localhost:3885/guestRecords';
            const requestBody = { ...data };
            console.log(requestBody);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error('Failed to post data to API');
            }
            return response.json();
        } catch (error) {
            console.error('Error posting data to API:', error);
            throw error;
        }
    };
    return(
        <div className='guestForm'>

            
            <div className='guestForm__container'>
                {/* <p>Please Enter Your Information Below</p> <br></br> */}
                
                <div className='guestForm__container--inputs'>
                    <p>Please Enter Your Information </p> <br></br>
                    <form>
                       
                        <label htmlFor="FN">First Name:</label>
                            <input type="text" id="FN" name="FN" value={formData.FN} onChange={handleChange} /> <br></br>
                        <label htmlFor="LN">Last Name:</label>
                        <input type="text" id="LN" name="LN"value={formData.LN} onChange={handleChange} /> <br></br>
                        {/* <input type="text" id="LN">Last Name:</input><br></br> */}
                        <label htmlFor="date">Date:</label>
                            <span id="day">{formData.date} </span><br></br>
                        <label htmlFor="company">Place of Work:</label>
                        {/* <input type="text" id="company"/><br></br> */}
                        <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} /> <br></br>
                            <div className='guestForm__container--button'>
                                <button type="button" onClick={() => {setChkInOut(); }}>{isCheckedIn ? 'Check Out' : 'Check In'}</button>
                            </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
