import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { PageLayout } from './components/AZURE-MSALcomps/PageLayout';
import { loginRequest } from './components/AZURE-MSALcomps/config';
import { callMsGraph } from './graphConfig';
import { ProfileData } from './components/AZURE-MSALcomps/ProfileData';
import { v4 as uuidv4 } from 'uuid';
import GuestForm from './components/guestForm/GuestForm.jsx';

import pageStyle from "./styles/App/app.scss"
import Nav from './styles/Nav/nav.scss'

const App = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [uuid, setUuid] = useState(null);
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const [checkOutTime, setCheckOutTime] = useState(null);
//Attempt to set check-time
    const [checkInTime, setCheckInTime]= useState(null);


// Will be implementing this via component     
    const [userLocation, setUserLocation] = useState(null);
    const [isAtLocation, setIsAtLocation] = useState(false);

     // Function to get user's current location
     const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };
    console.log(userLocation);
    
    // Run getLocation when component mounts
    // useEffect(() => {
    //     getLocation();
    // }, []);

// // Run checkIfAtLocation when userLocation updates
//     //Going move this useEffect call to the RequestProfileData function.
    // useEffect(() => {
    //     if (userLocation) {
    //         checkIfAtLocation();
    //     }
    // }, [userLocation]);

    const toRadians = (degrees) => {
        return degrees * Math.PI / 180;
    };
    // Function to check if the user is at the specified location
    const checkIfAtLocation = () => {
        const targetLocation = {
            latitude: 45.5956741,
            longitude: -73.7469028
        };
        console.log(userLocation);
        const earthRadius = 6371000; // Earth's radius in meters

        // Convert coordinates to radians
        const lat1 = toRadians(userLocation.latitude);
        const lon1 = toRadians(userLocation.longitude);
        // const lat1 = toRadians(45.6104);
        // const lon1 = toRadians(-73.7830);
        const lat2 = toRadians(targetLocation.latitude);
        const lon2 = toRadians(targetLocation.longitude);

        // Calculate the differences
        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;

        // Use Haversine formula to calculate the distance
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(lat1) * Math.cos(lat2) *
                  Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c; // Distance in meters

        // If the distance is less than a certain threshold (e.g., 100 meters), consider the user to be at the location
        const threshold = 1000; // in meters
        setIsAtLocation(distance <= threshold);
    };

   
    const ProfileContent = () => {
        function RequestProfileData() {
             // Get user's location before requesting profile data
    
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    callMsGraph(response.accessToken).then((response) => {
                    setGraphData(response);
                    getLocation();
                    });
                    //console.log(response);
                });
        }

        
        useEffect(() => {
            if (userLocation) {
                checkIfAtLocation();
            }
        }, []);
        console.log(isAtLocation);
        return (
            //Hidding this to make sure the graph data is still avalible.
            // <>
            // <ProfileData graphData={graphData} />
            //     <button onClick={RequestProfileData}>
            //              Request Profile Information</button>
            // </>
            // what looks to be missing in the statement above 
            //  -The check for if the graph data is avalible
            <>
                <h5 className="card-title" hidden >Welcome </h5>
                <br/>
                {graphData ? (
                    <ProfileData graphData={graphData} />
                ) : 
                (
                    <p>
                        {RequestProfileData()}
                    </p>
                    
                )}
            </>
        )
    };

    const generateUUID = ( ) => {
        const newUuid = uuidv4();
        setUuid(newUuid);
    };

    useEffect(() => {
        const chkTime= new Date().toLocaleTimeString();
        setCheckInTime(chkTime);
    }, [])
    console.log(checkInTime);

    const setChkInOut = async () => {
        try {
            const currentTime = new Date().toLocaleTimeString();
            const Username = graphData ? graphData.givenName : '';
            const action = isCheckedIn ? 'CheckOut' : 'CheckIn'; // Determine the action

            if (action === 'CheckIn'){
                generateUUID();
            }
    
            // Construct the data to be sent
            const data = {
                ID: uuid,
                Username: Username,
                CheckInTime: action === 'CheckIn' ? currentTime :  checkInTime,
                //CheckOutTime: action === 'CheckOut' ? currentTime : null
                CheckOutTime: action === 'CheckOut' ? currentTime : checkOutTime
            };
            // Post data to the server
            if(action ==='CheckOut'){
                await postData(data);
                console.log(data);
            }
    
            // Update local state
            if (isCheckedIn) {
                setCheckOutTime(currentTime);
            }
            setIsCheckedIn(!isCheckedIn);
        } catch (error) {
            console.error('Error:', error);
        }
    };

 

    const postData = async (data) => {
        try {
            const url = 'http://localhost:3885/setTable';
            const requestBody = { ...data };
            console.log(requestBody);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error('Failed to post data to API');
            }
            return response.json();
        } catch (error) {
            console.error('Error posting data to API:', error);
            throw error;
        }
    };

    // const postData = async (data, Username) => {
    //     try {
    //         const url = 'http://localhost:3885/setTable';
    //         const requestBody = { ...data, Username };
    //         const response = await fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(requestBody),
    //         });
    //         if (!response.ok) {
    //             throw new Error('Failed to post data to API');
    //         }
    //         return response.json();
    //     } catch (error) {
    //         console.error('Error posting data to API:', error);
    //         throw error;
    //     }
    // };

    return (
        <PageLayout>
            <div className="App">
                <AuthenticatedTemplate>
                    <ProfileContent />
                    <div className="ChkHolder">
                        {graphData && (
                            <div className='Login'>
                                <div className="Login__Holder">
                                    <span>Welcome,  {graphData.givenName}!</span>
                                    <div className="Login__Holder--Date"> 
                                        <p >{new Date().toLocaleDateString()}</p>
                                        <p>{new Date().toLocaleTimeString()}</p>
                                    </div>
                                    <div className="Login__Holder--Button">
                                        <button onClick={setChkInOut}>{isCheckedIn ? 'Check Out' : 'Check In'}</button>
                                        {/* <div className='LoginHolder__Button--logout'>
                                            <button>Logout</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {/* <h5 className="card-title">Please sign-in to see your profile information.</h5> */}
                    <GuestForm/>
                </UnauthenticatedTemplate>
            </div>
        </PageLayout>
    );
}

export default App;

