import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
  
    return (
      <>
      
          <div className="collapse navbar-collapse justify-content-end">
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </div>
        <br />
        <br />
        <h4>
          <center>
            Zef Scientific Sign-In Application
          </center>
        </h4>
        <br />
        <br />
        {props.children}
      </>
    );
  };