import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../AZURE-MSALcomps/config";

import appNavStyle from '../../styles/Nav/authorizedNav.scss';

import Logo from '../../Assets/logo.png';

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
      
        <div className="nav">
        <div className="nav__left">
            
            <button className="nav__left--button" onClick={() => handleLogin("redirect")}><b>Staff Login</b></button>
            
        </div>
        <div className="nav__logo">
            
                <img src={Logo} className="logo" alt="Zef Scientific Logo" />
        </div>
    </div>    
    )
}