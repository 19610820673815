import React from "react";
import { useMsal } from "@azure/msal-react";

import Logo from '../../Assets/logo.png';
import NavStyle from '../../styles/Nav/nav.scss';

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        
        <div className="nav">
            <div className="nav__left">
                <table variant="secondary" className="ml-auto" drop="start" title="Sign Out">
                    {/* <td as="button" onClick={() => handleLogout("popup")} hidden>Sign out using Popup</td> */}
                    <button className="nav__left--button" onClick={() => handleLogout("redirect")}>Logout</button>
                    {/* <td as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</td> */}
                </table>
            </div>
            <div className="nav__logo">
                    <img src={Logo} className="logo" alt="Zef Scientific Logo" />
            </div>
        </div>    
    )
}